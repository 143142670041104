import React from 'react'
import ProtectedRoute from '../ProtectedRoute';
import Workbook from 'react-excel-workbook';
import { Container, Col, Row, Card, Alert, Modal } from 'react-bootstrap';
import './UploadForm.css'

import Uploader from '../../components/uploader/Uploader';

import ApiFiles from '../../services/api/files';
import ProgressDialog from '../../components/progress_dialog/ProgressDialog';

class UploadForm extends React.Component {
  state = {
    isLoading: false,
    error: false,
    errorData: [],
    success: false,
    succMessage: '',
    errorMessage: '',

    message: 'Proses preprocess data memakan waktu cukup lama. ' + 
             'Mohon untuk tidak meninggalkan halaman ini dan menunggu beberapa menit..',
    copied: false,
  }

  _handleAlertClose = () => {
    this.setState({
      error: false,
      success: false,
      errorData: [],
    });
  }

  _handleUpload = (files) => {
    this.setState({
      isLoading: true,
    });

    const data = new FormData();
    files.forEach((file) => {
      data.append('files[]', file);
    });

    ApiFiles.uploadFiles(
      data
    )
      .then((res) => {
        this.setState({
          filenames: [files.map((file) => file.name)],
          errorData: res.data.error_data || [],
          isLoading: false,
          success: true,
          succMessage: 'Data berhasil disimpan'
        });
      })
      .catch((e) => {
        this.setState({
          error: true,
          errorMessage: 'Data gagal disimpan',
          isLoading: false,
        });
      });
  }

  _renderSheetTableModal = () => {
    return (
      <>
        <Modal className="custom-modal"
          show={this.state.errorData.length > 0}
          onHide={this._handleAlertClose}
        >
          <Modal.Header closeButton>
            <h4>Data yang Missing/Incomplete</h4>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <p>
                  Mohon untuk memperbaiki data dibawah ini dan mengunggahnya kembali pada uploader yang disediakan
                </p>
              </Col>
              <Col md="auto" className="text-right mr-2">
                <Workbook filename={this.state.filenames[0]} element={<button className="btn btn-primary ml-3">Download as XLS</button>}>
                  <Workbook.Sheet data={this.state.errorData} name="Sheet1">
                    {
                      Object.keys(this.state.errorData[0]).map((key) => (
                        <Workbook.Column key={key} label={key} value={key} />
                      ))
                    }
                  </Workbook.Sheet>
                </Workbook>
              </Col>
            </Row>
            <table
              id="table-error-data"
              className="table table-hover ml-3 mt-3"
              style={{
                display: 'block',
                overflowX:'auto',
                overflowY: 'auto',
                width: '95%',
                height: '58vh'
              }}>
              <thead className="table-primary">
                <tr>
                  {
                    Object.keys(this.state.errorData[0]).map((key) => {
                      return (
                        <th key={key} scope="col">
                          {key}
                        </th>
                      )
                    })
                  }
                </tr>
              </thead>
              <tbody>
                {
                  this.state.errorData.map((data, idx) => {
                    return (
                      <tr key={idx}>
                        {
                          Object.keys(data).map((key) => (
                            <td key={key}>{data[key]}</td>
                          ))
                        }
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </Modal.Body>
        </Modal>
      </>
    )
  }

  render() {
    const { error, errorData, isLoading, errorMessage, succMessage, success, message } = this.state;

    return (
      <ProtectedRoute>
        <ProgressDialog show={isLoading} message={message} />
        <Alert
          show={error || success}
          className="alert-custom"
          variant={error ? 'danger': 'success' }>
          {error ? errorMessage : succMessage}
          <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this._handleAlertClose}>
            <span aria-hidden="true">&times;</span>
          </button>
        </Alert>
        <Container className="py-3 container-upload-form">
          <Row>
            <Col sm={8}>
              <Card className="card-upload-form">
                <Card.Header style={{backgroundColor:"#C0F6F7"}}>
                  <h1 className="text-left">
                    <strong>
                      Upload Files
                    </strong>
                  </h1>
                </Card.Header>
                <Card.Body>
                  {/* <Card.Title className="text-left">
                    Pilih berkas yang ingin diunggah:
                  </Card.Title> */}
                  <Uploader onClick={this._handleUpload} loading={isLoading} />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        {
          errorData.length > 0 && this._renderSheetTableModal()
        }
      </ProtectedRoute>
    )
  }
}

export default UploadForm;