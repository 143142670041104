export function mapKeyToCol(key) {
  switch (key) {
    case "id":
      return "NIM/NIDN/NIDK/NIP/No. SPKWT/SK";
    case "age":
      return "Usia";
    case "respondent_type":
      return "Jenis Responden";
    case "name":
      return "Nama";
    case "department_id":
      return "Kode Fakultas";
    case "contacts":
      return "No. Hp";
    case "faculty":
      return "Fakultas";
    case "department_name":
      return "Jurusan";
    case "residence_type":
      return "Jenis Tmp Tgl";
    case "timestamp":
      return "Tanggal";
    case "status":
      return "Kasus";
    case "district":
      return "Kecamatan";
    case "regency":
      return "Kota";
    case "has_symptoms":
      return "Ada Gejala?";
    case "good_condition":
      return "Kondisi Fisik";
    case "destination":
      return "Kabupaten/Kota Keluar";
    default:
      return key;
  }
}