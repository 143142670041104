import React from 'react'

export default class Logout extends React.Component {
  async componentDidMount() {
    await localStorage.clear();

    this.props.history.push('login');
  }

  render() {
    return (
      <> </>
    )
  }
};