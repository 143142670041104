import React from 'react'
import { Modal } from 'react-bootstrap';

import './ProgressDialog.css';

class ProgressDialog extends React.Component {
  
  render() {
    return (
      <Modal
        {...this.props}
        size="md"
        centered
      >
        <Modal.Body className="p-4">
          <p className="text-justify">
            {this.props.message}
          </p>
          <div className="progress-bar">
            <div className="line"></div>
            <div className="block-line inc"></div>
            <div className="block-line dec"></div>
          </div>
        </Modal.Body>
      </Modal>
    ); 
  }
}

export default ProgressDialog;