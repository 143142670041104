import React from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import Select from "react-select";
import Workbook from 'react-excel-workbook';
import Pagination from "../../../../components/pagination/Pagination";
import { mapKeyToCol } from '../../../../utils/mappingcol';

import EditCompletedRecordModal from '../edit';
import { Alert } from 'react-bootstrap';

import ApiReport from "../../../../services/api/report";

class CompletedTable extends React.Component {
  state = {
    completed: {
      startTime: moment({ year: 2020, month: 2, day: 28 }).format("X"),
      endTime: moment().format("X"),
      startTimeText: moment({ year: 2020, month: 2, day: 28 }).format(
        "DD/MM/YYYY"
      ),
      endTimeText: moment().format("DD/MM/YYYY"),
      page: 1,
      perPage: 10,
      totalPages: 0,
      respondentTypes: ["All"],
      departmentIds: ["All"],
      status: ["All"],
      sort: {
        icon: "fa fa-fw fa-sort-asc",
        value: "asc",
      },
      data: [],
    },

    //Table data for download purpose
    tableData: {
      completed: {
        data: [],
        downloadable: false,
      },
    },

    sort: {
      asc: {
        icon: "fa fa-fw fa-sort-asc",
        value: "asc",
      },
      desc: {
        icon: "fa fa-fw fa-sort-desc",
        value: "desc",
      },
    },

    showModal: false, 
    waitForEdit: false,
    errorOnEdit: false,
    successOnEdit: false,
    alertMessage: '', 
    dataToEdit: {
      name: '',
      id: '',
    }
  }

  componentDidMount() {
    this._getFilteredCompletedReport();
    this._getWorkSheet('completed');
  }

  componentDidUpdate(_, prevState) {
    if (prevState.completed.startTime !== this.state.completed.startTime) {
      this._getFilteredCompletedReport();
      this._getWorkSheet('completed');
    }
    if (prevState.completed.sort.value !== this.state.completed.sort.value) {
      this._getFilteredCompletedReport();
      this._getWorkSheet('completed');
    }
  }

  _getFilteredCompletedReport = () => {
    const {
      startTime,
      endTime,
      respondentTypes,
      departmentIds,
      status,
      page,
      perPage,
      sort,
    } = this.state.completed;

    const filter = {
      date_end: moment(endTime, "X").format("YYYY-MM-DD"),
      date_start: moment(startTime, "X").format("YYYY-MM-DD"),
      department_ids: departmentIds,
      is_completed: true,
      mode: "detail",
      respondent_types: respondentTypes,
      status,
    };

    ApiReport.getFilteredReportWithPagination(
      filter,
      page,
      perPage,
      sort.value
    ).then((res) => {
      const { data } = res;

      //Convert array to string
      data.respondents.forEach((obj) => {
        obj['contacts'] = obj['contacts'].toString();
      })
      
      this.setState({
        completed: {
          ...this.state.completed,
          data: data.respondents,
          totalPages: data.total_pages,
        },
      });
    });
  };

  _getWorkSheet = (type) => {
    const {
      startTime,
      endTime,
      respondentTypes,
      departmentIds,
      status,
      sort,
    } = this.state[type];

    const filter = {
      date_end: moment(endTime, "X").format("YYYY-MM-DD"),
      date_start: moment(startTime, "X").format("YYYY-MM-DD"),
      department_ids: departmentIds || ['All'],
      is_completed: type === 'completed' ? true : false,
      mode: "detail",
      respondent_types: respondentTypes || ['All'],
      status: status || ['All'],
    };

    this.setState({
      tableData: {
        ...this.state.tableData,
        [type]: {
          ...this.state.tableData[type],
          downloadable: false,
        }
      }
    })

    ApiReport.getFilteredReportWithPagination(
      filter,
      1,
      9999,
      sort.value
    )
      .then((res) => {
        const { data } = res;

        //Convert array to string
        data.respondents.forEach((obj) => {
          obj['contacts'] = obj['contacts'].toString();
        });

        this.setState({
          tableData: {
            ...this.state.tableData,
            [type]: {
              data: data.respondents,
              downloadable: true,
            }
          }
        })
      });
  }

  _hideEditModal = (succ) => {
    if (typeof(succ) === "undefined") {
      this.setState({
        showModal: false,
      });
      return;
    }

    let obj = {};
    if (succ) {
      obj = {
        successOnEdit: true,
        alertMessage: 'Data berhasil diperbarui!',
      }
    } else {
      obj = {
        errorOnEdit: true,
        alertMessage: 'Data gagal diperbarui!',
      }
    }

    this.setState({
      ...this.state,
      ...obj,
      showModal: false,
      waitForEdit: false,
    }, () => {
      if (succ) {
        this._getFilteredCompletedReport();
        this._getWorkSheet('completed');
      }
    });
  }

  _openEditModal = (data) => {
    this.setState({
      dataToEdit: data,
      showModal: true
    });
  }

  _handleAlertClose = () => {
    this.setState({
      ...this.state,
      errorOnEdit: false,
      successOnEdit: false
    });
  }

  _handleApplyDate = (_, picker) => {
    this.setState({
      completed: {
        ...this.state.completed,
        startTime: moment(picker.startDate).format("X"),
        endTime: moment(picker.endDate).format("X"),
        startTimeText: moment(picker.startDate).format("DD/MM/YYYY"),
        endTimeText: moment(picker.endDate).format("DD/MM/YYYY"),
      },
    });
  };

  render() {
    const { completed, errorOnEdit, successOnEdit, alertMessage } = this.state;
    const { filter } = this.props;

    return (
      <div className="row my-3">
        <Alert
          show={errorOnEdit || successOnEdit}
          className="alert-custom"
          variant={errorOnEdit ? 'danger': 'success' }>
          {alertMessage}
          <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this._handleAlertClose}>
            <span aria-hidden="true">&times;</span>
          </button>
        </Alert>
        {
          this.state.showModal &&
          <EditCompletedRecordModal
            filter={filter}
            loading={this.state.waitForEdit}
            show={this.state.showModal}
            data={this.state.dataToEdit}
            onHide={this._hideEditModal}
            onSubmit={(data) => {
                this.setState({
                  waitForEdit: true,
                });

                data['id'] = data['civitas_id'];
                ApiReport.updateReport(data, data.report_id)
                  .then(() => {
                    this._hideEditModal(true);
                  })
                  .catch((_) => {
                    this._hideEditModal(false);
                  });
              }}
            />
        }
        <div className="col text-left">
          <h2>Daftar Responden "Completed"</h2>
        </div>
        <div className="col-12 mt-2 text-left">
          <div className="row">
            <div className="col-12 my-2">Filter Data</div>
            <div className="col">
              <DateRangePicker
                onApply={(e, picker) =>
                  this._handleApplyDate(e, picker)
                }
                maxDate={moment().format("MM/DD/YYYY")}
              >
                <form className="form-inline">
                  <div className="form-group mb-2" style={{ width: '27%' }}>
                    <input
                      type="text"
                      style={{
                        width: '100%'
                      }}
                      className="form-control"
                      name="date-input"
                      placeholder="Start Date"
                      value={completed.startTimeText}
                    />
                  </div>
                  <p className="mt-2 mx-3">s/d</p>
                  <div className="form-group mb-2" style={{ width: '27%' }}>
                    <input
                      type="text"
                      style={{
                        width: '100%'
                      }}
                      className="form-control"
                      name="date-input"
                      placeholder="End Date"
                      value={completed.endTimeText}
                    />
                  </div>
                </form>
              </DateRangePicker>
            </div>
          </div>
          <div className="row" style={{ paddingLeft: '7px' }}>
            <div className="mx-2" style={{ width: "17%" }}>
              <Select
                isMulti
                placeholder="Jenis Responden"
                onChange={(newValue) => {
                  let respondentTypes;
                  if (newValue === null || newValue.length === 0) respondentTypes = ["All"];
                  else respondentTypes = newValue.map((value) => value.value);

                  this.setState(
                    {
                      completed: {
                        ...this.state.completed,
                        respondentTypes,
                      },
                    },
                    () => {
                      this._getFilteredCompletedReport();
                      this._getWorkSheet('completed');
                    }
                  );
                }}
                options={filter.respondentTypes.map((e) => ({
                  value: e,
                  label: e,
                }))}
              ></Select>
            </div>
            <div className="mx-2" style={{ width: "17%" }}>
              <Select
                isMulti
                placeholder="Prodi"
                onChange={(newValue) => {
                  let departmentIds;
                  if (newValue === null || newValue.length === 0) departmentIds = ["All"];
                  else departmentIds = newValue.map((value) => value.value);

                  this.setState(
                    {
                      completed: {
                        ...this.state.completed,
                        departmentIds,
                      },
                    },
                    () => {
                      this._getFilteredCompletedReport();
                      this._getWorkSheet('completed');
                    }
                  );
                }}
                options={filter.majors.map((e) => ({
                  value: e.id,
                  label: e.name,
                }))}
              ></Select>
            </div>
            <div className="mx-2" style={{ width: "12%" }}>
              <Select
                isMulti
                placeholder="Status"
                onChange={(newValue) => {
                  let status;
                  if (newValue === null || newValue.length === 0) status = ["All"];
                  else status = newValue.map((value) => value.value);

                  this.setState(
                    {
                      completed: {
                        ...this.state.completed,
                        status,
                      },
                    },
                    () => {
                      this._getFilteredCompletedReport();
                      this._getWorkSheet('completed');
                    }
                  );
                }}
                options={filter.status.map((e) => ({
                  value: e,
                  label: `Kasus ${e}`,
                }))}
              ></Select>
            </div>
            <div className="col p-0 mx-2 pull-right" style={{ width: "15%" }}>
              <Workbook filename={`AMARI_Covid19-Completed-${moment().format('X')}.xlsx`} element={
                  <button className="btn btn-primary ml-3 pull-right" disabled={!this.state.tableData.completed.downloadable}>Download as XLS</button>
              }>
                {
                  this.state.tableData.completed.data.length > 0 && (
                    <Workbook.Sheet data={this.state.tableData.completed.data} name="Sheet1" >
                      {
                        Object.keys(this.state.tableData.completed.data[0]).map((key) => {
                          return (
                            <Workbook.Column key={key} label={mapKeyToCol(key)} value={key} />
                          )
                        })
                      }
                    </Workbook.Sheet>
                  )
                }
              </Workbook>
            </div>
          </div>
        </div>
        <table className="table table-responsive table-hover ml-3 mt-3">
          <caption>
            <Pagination
              id={"table-response-complete"}
              activePage={completed.page}
              totalPage={completed.totalPages}
              onPreviousClick={() => {
                this.setState(
                  (prevState) => ({
                    completed: {
                      ...prevState.completed,
                      page: prevState.completed.page - 1,
                    },
                  }),
                  this._getFilteredCompletedReport
                );
              }}
              onPageNumberClick={(num) => {
                this.setState(
                  (prevState) => ({
                    completed: {
                      ...prevState.completed,
                      page: num,
                    },
                  }),
                  this._getFilteredCompletedReport
                );
              }}
              onNextClick={() => {
                this.setState(
                  (prevState) => ({
                    completed: {
                      ...prevState.completed,
                      page: prevState.completed.page + 1,
                    },
                  }),
                  this._getFilteredCompletedReport
                );
              }}
            />
          </caption>
          <thead className="table-primary">
            <tr>
              <th scope="col">
                Tanggal
                <i
                  className={this.state.completed.sort.icon}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    const currValue = this.state.completed.sort.value;
                    let valueNow;

                    if (currValue === "asc") valueNow = "desc";
                    else valueNow = "asc";

                    this.setState({
                      completed: {
                        ...this.state.completed,
                        sort: this.state.sort[valueNow],
                      },
                    });
                  }}
                ></i>
              </th>
              <th scope="col">Nama</th>
              <th scope="col">Usia</th>
              <th scope="col">No. Hp</th>
              <th scope="col">Jenis Responden</th>
              <th scope="col">NIM/NIDN/NIDK/NIP/No. SPKWT/SK</th>
              <th scope="col">Prodi</th>
              <th scope="col">Ada Gejala?</th>
              <th scope="col">Kondisi Fisik</th>
              <th scope="col">Status</th>
              <th scope="col">Jenis Tmp Tgl</th>
              <th scope="col">Kabupaten/Kota Keluar</th>
              <th scope="col">Kecamatan</th>
              <th scope="col">Kota</th>
            </tr>
          </thead>
          <tbody>
            {completed.data.length === 0 && (
              <tr>
                <td colSpan="14">No data</td>
              </tr>
            )}
            {completed.data.map((data, idx) => (
              <tr key={idx} onClick={() => this._openEditModal(data)} style={{ cursor: "pointer" }}>
                <th scope="row">
                  {moment(data.timestamp).format("DD/MM/YYYY HH:mm")}
                </th>
                <td>{data.name || "-"}</td>
                <td>{data.age || "-"}</td>
                <td>
                  <ul className="list-group">
                    {data.contacts.split(',').map((contact, idx) => (
                      <li key={idx} className="list-group-item">{contact}</li>
                    ))}
                  </ul>
                </td>
                <td>{data.type || "-"}</td>
                <td>{data.id || "-"}</td>
                <td>{data.department_name || "-"}</td>
                <td>{data.has_symptoms ? "Ya" : "Tidak"}</td>
                <td>{data.good_condition ? "Baik" : "Kurang Baik"}</td>
                <td>{data.status || "-"}</td>
                <td>{data.residence_type || "-"}</td>
                <td>{data.destination || "-"}</td>
                <td>{data.district || "-"}</td>
                <td>{data.regency || "-"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
};

export default CompletedTable; 