export default {
  items: [
    {
      name: "Home",
      url: "dashboard",
      icon: "fa fa-fw fa-home",
      children: [
        {
          name: "Dashboard",
          url: "dashboard",
        },
        {
          name: "List Civitas",
          url: "dashboard/civitas/list",
        },
      ],
    },
    {
      name: "Admin",
      url: "admin",
      icon: "fa fa-fw fa-user",
      children: [
        {
          name: "Tambah Admin",
          url: "admin/add",
        },
      ],
    },
    {
      name: "Forms",
      url: "forms",
      icon: "fa fa-fw fa-wpforms",
      children: [
        {
          name: "Upload Data",
          url: "forms/upload-data",
        },
      ],
    },
    {
      name: "Log out",
      url: "logout",
      icon: "fa fa-fw fa-sign-out",
    },
    //Add another routes here!
  ],
  civitas: [
    {
      name: "Home",
      url: "dashboard",
      icon: "fa fa-fw fa-home",
    },
    {
      name: "Log out",
      url: "logout",
      icon: "fa fa-fw fa-sign-out",
    },
    //Add another routes here!
  ],
};
