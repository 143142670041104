import React from "react";
import "bootstrap-daterangepicker/daterangepicker.css";

import Spacing from '../../components/spacing/Spacing';
import Pagination from "../../components/pagination/Pagination";
import moment from "moment";

import ProtectedRoute from "../ProtectedRoute";
import "./Dashboard.css";

import ApiAttrbs from "../../services/api/attributes";
import ApiHistory from "../../services/api/history";

import TotalSummary from "./TotalSummary";
import BarChartSummary from "./BarChartSummary";
import CompletedSummary from "./CompletedSummary";
import banner from "../../assets/banner.png";

import CompletedTable from "./Table/Completed";
import NonCompletedTable from "./Table/NonCompleted";

const sortTypes = {
  asc: {
    icon: "fa fa-fw fa-sort-asc",
    value: "asc",
  },
  desc: {
    icon: "fa fa-fw fa-sort-desc",
    value: "desc",
  },
};

class Dashboard extends React.Component {
  state = {
    filter: {
      respondentTypes: [],
      majors: [],
      status: ["A", "B", "C", "D"],
    },
    detail: {
      selectedName: localStorage.getItem("CV_ITB_CIVITAS_NAME"),
      selectedID: localStorage.getItem("CV_ITB_CIVITAS_ID"),
      sort: sortTypes.asc,
      page: 1,
      perPage: 10,
      totalPage: 1,
      total: 0,
      data: [],
    },
  };

  componentDidMount() {
    this._getFilterAttributes();
    this._getRespondentHistory();
  }

  componentDidUpdate(_, prevState) {
    if (prevState.detail.sort.value !== this.state.detail.sort.value) {
      this._getRespondentHistory();
    }
  }

  _getRespondentHistory = async () => {
    const { selectedID, page, perPage } = this.state.detail;
    const { value } = this.state.detail.sort;

    await ApiHistory.getRespondentHistory(selectedID, page, perPage, value).then(
      (res) => {
        const { data } = res;

        this.setState({
          detail: {
            ...this.state.detail,
            data: data.history,
            totalPage: data.total_pages,
          },
        });
      }
    );
  };

  _getFilterAttributes = () => {
    ApiAttrbs.getRespondentTypes().then((res) => {
      this.setState({
        filter: {
          ...this.state.filter,
          respondentTypes: res.data.respondent_types,
        },
      });
    });

    ApiAttrbs.getDepartments().then((res) => {
      this.setState({
        filter: {
          ...this.state.filter,
          majors: res.data.departments,
        },
      });
    });
  };

  render() {
    return (
      <ProtectedRoute>
        <div className="container-dashboard">
          {/* Personal Information */}
          <div className="row">
            {/* Dashboard */}
            <div className="col-sm-12 p-4">
              <div className="row mb-4">
                <img src={banner} alt="Amari ITB Banner" className="banner" />
              </div>
              <div className="row mb-4">
                <div className="col-auto text-left">
                  <h1>AMARI Overview</h1>
                </div>
              </div>
              {/* Statistics */}
              <TotalSummary />
              <Spacing line={true} />
              <CompletedSummary />
              <Spacing line={true} />
              <BarChartSummary />
              <Spacing line={true} />
              {localStorage.getItem("CV_ITB_ROLE") !== "Civitas" ? <NonCompletedTable filter={this.state.filter} /> :
                <div>
                  <h4>
                    Detail Historis - {this.state.detail.selectedName} -{" "}
                    {this.state.detail.selectedID}
                  </h4>
                  <table
                    className="table table-responsive table-hover ml-3 mt-3"
                    style={{
                      display: "block",
                      overflowX: "auto",
                      overflowY: "auto",
                      width: "95%",
                      height: "58vh",
                    }}
                  >
                    <thead className="table-primary">
                      <th scope="col">
                        Tanggal
                        <i
                          className={this.state.detail.sort.icon}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            const currValue = this.state.detail.sort.value;
                            let valueNow;

                            if (currValue === "asc") valueNow = "desc";
                            else valueNow = "asc";

                            this.setState({
                              detail: {
                                ...this.state.detail,
                                sort: sortTypes[valueNow],
                              },
                            });
                          }}
                        ></i>
                      </th>
                      <th scope="col">Nama</th>
                      <th scope="col">NIM/NIDN/NIDK/NIP/No. SPKWT/SK</th>
                      <th scope="col">No. Hp</th>
                      <th scope="col">Ada Gejala?</th>
                      <th scope="col">Kondisi Fisik?</th>
                      <th scope="col">Kabupaten/Kota Keluar</th>
                    </thead>
                    <tbody>
                      {this.state.detail.data.map((data, idx) => (
                        <tr key={idx}>
                          <td>{moment(data.timestamp).format("DD/MM/YYYY HH:mm")}</td>
                          <td>{this.state.detail.selectedName}</td>
                          <td>{this.state.detail.selectedID}</td>
                          <td>
                            <ul className="list-group">
                              {data.contacts.map((contact) => (
                                <li key={contact} className="list-group-item">{contact}</li>
                              ))}
                            </ul>
                          </td>
                          <td>{data.has_symptoms ? "Ya" : "Tidak"}</td>
                          <td>{data.good_condition ? "Baik" : "Kurang Baik"}</td>
                          <td>{data.destination || "-"}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Pagination
                    activePage={this.state.detail.page}
                    totalPage={this.state.detail.totalPage}
                    onPreviousClick={() => {
                      this.setState(
                        (prevState) => ({
                          detail: {
                            ...prevState.detail,
                            page: prevState.detail.page - 1,
                          },
                        }),
                        this._getRespondentHistory
                      );
                    }}
                    onPageNumberClick={(num) => {
                      this.setState(
                        (prevState) => ({
                          detail: {
                            ...prevState.detail,
                            page: num,
                          },
                        }),
                        this._getRespondentHistory
                      );
                    }}
                    onNextClick={() => {
                      this.setState(
                        (prevState) => ({
                          detail: {
                            ...prevState.detail,
                            page: prevState.detail.page + 1,
                          },
                        }),
                        this._getRespondentHistory
                      );
                    }}
                  />
                </div>}
              <Spacing line={true} />
              {localStorage.getItem("CV_ITB_ROLE") !== "Civitas" ? <CompletedTable filter={this.state.filter} /> : null}
            </div>
          </div>
        </div>
      </ProtectedRoute>
    );
  }
}

export default Dashboard;
