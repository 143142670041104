import React from "react";

import styles from "./Fallback.module.css";

const FallbackComponent = ({ componentStack, error }) => (
  <div className={styles.container}>
    <p className={styles.title}>
      <strong>Terjadi Kesalahan! Silakan laporkan pada pengembang</strong>
    </p>
    <p className={styles.subtitle}>Mohon kirim tangkapan layar berikut</p>
    <p className={styles.description}>
      <strong>Error:</strong> {error.toString()}
    </p>
    <p className={styles.description}>
      <strong>Stacktrace:</strong> {componentStack}
    </p>
  </div>
);

export default FallbackComponent;
