import React from "react";
import './Spacing.css';

class Spacing extends React.Component {
  render() {
    return (
      <div className="py-4 my-3">
        { this.props.line && (<hr />) }
      </div>
    )
  }
}

export default Spacing;