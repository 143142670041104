import React from "react";
import dayjs from "dayjs";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Select from "react-select";
import { Pie, Line } from "react-chartjs-2";

import ApiAttrbs from "../../../services/api/attributes";
import ApiReport from "../../../services/api/report";

class CompletedSummary extends React.Component {
  state = {
    completedData: {
      respondents: null,
      summary: {},
      daily_summary: {},
    },
    filterData: {
      respondentTypes: [],
      departments: [],
    },
    filter: {
      startDate: "2020-03-28",
      endDate: dayjs().format("YYYY-MM-DD"),
      respondentTypes: ["All"],
      departments: ["All"],
    },
  };

  componentDidMount() {
    this._getFilterAttributes();
    this._getFilteredCompletedSummary();
  }

  _getFilteredCompletedSummary = () => {
    const {
      startDate,
      endDate,
      respondentTypes,
      departments,
    } = this.state.filter;

    const filter = {
      date_end: endDate,
      date_start: startDate,
      department_ids: departments,
      is_completed: true,
      mode: "summary",
      respondent_types: respondentTypes,
      status: ["All"],
    };

    ApiReport.getFilteredReport(filter).then((res) => {
      this.setState({ completedData: res.data });
    });
  };

  _getFilterAttributes = () => {
    ApiAttrbs.getRespondentTypes().then((res) => {
      this.setState({
        filterData: {
          ...JSON.parse(JSON.stringify(this.state.filterData)),
          respondentTypes: res.data.respondent_types,
        },
      });
    });

    ApiAttrbs.getDepartments().then((res) => {
      this.setState({
        filterData: {
          ...JSON.parse(JSON.stringify(this.state.filterData)),
          departments: res.data.departments,
        },
      });
    });
  };

  _getCompleteSummaryChartProps = () => {
    const { A, B, C, D, total } = this.state.completedData.summary;

    return {
      data: {
        labels: ["A", "B", "C", "D"],
        datasets: [
          {
            data: [A, B, C, D],
            backgroundColor: ["#3346D1", "#D50000", "green", "purple"],
            borderWidth: 0,
          },
        ],
      },
      legend: {
        position: "bottom",
        labels: {
          padding: 15,
        },
      },
      options: {
        title: {
          display: true,
          text: `Total Complete: ${total}`,
          fontSize: 24,
          fontColor: "#212121",
        },
        maintainAspectRatio: false,
        plugins: {
          labels: {
            fontColor: "#FAFAFA",
            precision: 1,
          },
        },
      },
    };
  };

  _getCompleteDailyChartProps = () => {
    const { daily_summary } = this.state.completedData;

    const dates = [];
    const As = [];
    const Bs = [];
    const Cs = [];
    const Ds = [];

    for (let date in daily_summary) {
      const { A, B, C, D } = daily_summary[date];

      dates.push(dayjs(date).locale("id").format("DD MMMM YYYY"));
      As.push(A);
      Bs.push(B);
      Cs.push(C);
      Ds.push(D);
    }

    return {
      data: {
        labels: dates,
        datasets: [
          {
            label: "A",
            data: As,
            fill: false,
            borderColor: "#3346D1",
          },
          {
            label: "B",
            data: Bs,
            fill: false,
            borderColor: "#D50000",
          },
          {
            label: "C",
            data: Cs,
            fill: false,
            borderColor: "green",
          },
          {
            label: "D",
            data: Ds,
            fill: false,
            borderColor: "purple",
          },
        ],
      },
      legend: {
        position: "bottom",
      },
      options: {
        maintainAspectRatio: false,
      },
    };
  };

  _renderCompletedFilter = () => {
    return (
      <div
        className="col"
        style={{
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <div style={{ width: "45%" }}>
          <label>Tipe Responden</label>
          <Select
            isMulti
            onChange={(newValue) => {
              let respondentTypes;
              if (!newValue || newValue.length === 0) respondentTypes = ["All"];
              else respondentTypes = newValue.map((value) => value.value);

              this.setState(
                {
                  filter: {
                    ...JSON.parse(JSON.stringify(this.state.filter)),
                    respondentTypes,
                  },
                },
                this._getFilteredCompletedSummary
              );
            }}
            options={this.state.filterData.respondentTypes.map((type) => ({
              value: type,
              label: type,
            }))}
          />
        </div>
        <div style={{ width: "45%" }}>
          <label>Program Studi</label>
          <Select
            isMulti
            onChange={(newValue) => {
              let departments;
              if (!newValue || newValue.length === 0) departments = ["All"];
              else departments = newValue.map((value) => value.value);

              this.setState(
                {
                  filter: {
                    ...JSON.parse(JSON.stringify(this.state.filter)),
                    departments,
                  },
                },
                this._getFilteredCompletedSummary
              );
            }}
            options={this.state.filterData.departments.map((department) => ({
              value: department.id,
              label: department.name,
            }))}
          />
        </div>
      </div>
    );
  };

  _renderCompletedDateRangePicker = () => {
    return (
      <div
        className="col"
        style={{
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <DateRangePicker
          onApply={(e, picker) => {
            this.setState(
              {
                filter: {
                  ...JSON.parse(JSON.stringify(this.state.filter)),
                  startDate: dayjs(picker.startDate).format("YYYY-MM-DD"),
                  endDate: dayjs(picker.endDate).format("YYYY-MM-DD"),
                },
              },
              this._getFilteredCompletedSummary
            );
          }}
          startDate={dayjs(this.state.filter.startDate).format("MM/DD/YYYY")}
          endDate={dayjs(this.state.filter.endDate).format("MM/DD/YYYY")}
          maxDate={dayjs().format("MM/DD/YYYY")}
        >
          <form className="form-inline">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="date-input"
                placeholder="Start Date"
                value={dayjs(this.state.filter.startDate)
                  .locale("id")
                  .format("DD MMMM YYYY")}
              />
            </div>
            <label className="mx-3">sampai</label>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="date-input"
                placeholder="End Date"
                value={dayjs(this.state.filter.endDate)
                  .locale("id")
                  .format("DD MMMM YYYY")}
              />
            </div>
          </form>
        </DateRangePicker>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="row mb-3">
          <div
            className="col-4 text-left"
            style={{ display: "flex", alignItems: "flex-end" }}
          ></div>
          {this._renderCompletedFilter()}
        </div>
        <div className="row mb-3">
          <div
            className="col-4 text-left"
            style={{ display: "flex", alignItems: "flex-end" }}
          >
            <h2>Statistik Completed</h2>
          </div>
          {this._renderCompletedDateRangePicker()}
        </div>
        <div className="row mb-4">
          <div className="cards-statistic col-3">
            <Pie {...this._getCompleteSummaryChartProps()} />
          </div>
          <div className="cards-statistic col">
            <Line {...this._getCompleteDailyChartProps()} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CompletedSummary;
