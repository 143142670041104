import { Http } from '../http';

const endpoint = 'history';

export default {
  getRespondentHistory(id, page = 1, perPage = 10, orderType = 'asc', orderBy = 'timestamp') {
    return new Promise((resolve, reject) => {
      Http.get(
        `${endpoint}/${id}?page=${page}&perPage=${perPage}&orderBy=${orderBy}&orderType=${orderType}`
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  }
};