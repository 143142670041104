import { Http } from "../http";

const endpoint = "report";

export default {
  getCompletenessTotal() {
    return new Promise((resolve, reject) => {
      Http.get(`${endpoint}/summary/completeness`)
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },
  getCompletenessRange({ startTime, endTime }) {
    let params = [];
    if (startTime) params.push(`startTime=${startTime}`);
    if (endTime) params.push(`endTime=${endTime}`);

    return new Promise((resolve, reject) => {
      Http.get(`${endpoint}/summary/completeness?${params.join("&")}`)
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },
  getReportUncompleted(page = 1, perPage = 10, startTime, endTime, orderType = 'asc', orderBy = 'timestamp') {
    return new Promise((resolve, reject) => {
      Http.get(
        `${endpoint}/uncompleted?startTime=${startTime}&endTime=${endTime}&perPage=${perPage}&page=${page}&orderBy=${orderBy}&orderType=${orderType}`
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },
  getFilteredReport(filter) {
    return new Promise((resolve, reject) => {
      Http.post(`${endpoint}/filter`, filter)
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },
  getFilteredReportWithPagination(filter, page = 1, perPage = 10, orderType = 'asc', orderBy = 'timestamp') {
    return new Promise((resolve, reject) => {
      Http.post(`${endpoint}/filter?page=${page}&perPage=${perPage}&orderBy=${orderBy}&orderType=${orderType}`, filter)
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },
  updateReport(report, reportId) {
    return new Promise((resolve, reject) => {
      Http.put(`${endpoint}/${reportId}`, report)
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    })
  }
};
