import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import './Uploader.css';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

function Uploader(props) {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
    onDrop: acceptedFiles => {
      setFiles(files => files.concat(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))));
    }
  });

  function _handleDeleteItem(idx) {
    setFiles(files.filter((_, index) => index !== idx));
  }

  const previews = files.map((file, idx) => (
    <li key={idx} className="mt-1 list-group-item list-group-item-action">
      {file.name}
      <span className="pull-right ml-2" onClick={() => _handleDeleteItem(idx)}>
        <div className="btn btn-danger">
          <i className="fa fa-trash" />
        </div>
      </span> 
    </li>
  ));

  useEffect(() => () => {
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <section className="container">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <div className="upload-drop-zone bg-covid-primary">
          Klik untuk memilih berkas atau taruh berkas disini
        </div>
      </div>
      <div>
        <p className="text-danger text-left">Note: File yang dapat diproses hanya file yang berekstensi *.xlsx, *xls, *csv</p>
      </div>
      <aside style={thumbsContainer}>
        <ul className="list-group w-100">
          { files.length > 0 ? <h2 className="text-left">Daftar berkas: </h2> : '' }
          {previews}
        </ul>
      </aside>
      <button className="col-12 pull-right py-2 mt-3 mb-2 btn-primary" onClick={() => props.onClick(files)} disabled={files.length === 0 || props.loading}>
        <span className="mr-2"><i className="fa fa-upload" aria-hidden="true"></i></span>
        { props.loading ? 'Uploading files...' : 'Upload' }
      </button>
    </section>
  );
}

export default Uploader;