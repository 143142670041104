import React from "react";
import { withRouter } from "react-router-dom";

class ProtectedRoute extends React.Component {
  async componentDidMount() {
    const token = await localStorage.getItem("CV_ITB_TOKEN");

    if (!token) {
      this.props.history.push("/login");
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ProtectedRoute);
