import { Http } from '../http';

const endpoint = 'files';

export default {
  uploadFiles(files) {
    return new Promise((resolve, reject) => {
      Http.postUpload(
        `${endpoint}/upload`,
        files
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  }
};