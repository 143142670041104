import React from "react";
import ReactDOM from "react-dom";
import ErrorBoundary from "react-error-boundary";
import "bootstrap/dist/css/bootstrap.min.css";
import "dayjs/locale/id";

import App from "./App";
import FallbackComponent from "./components/fallback/Fallback";
import "chartjs-plugin-labels";

import "./index.css";

ReactDOM.render(
  <ErrorBoundary FallbackComponent={FallbackComponent}>
    <App />
  </ErrorBoundary>,
  document.getElementById("root")
);
