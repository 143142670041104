import { Http } from "../http";

const endpoint = "accounts";

export default {
  login({ email, password }) {
    return new Promise((resolve, reject) => {
      Http.post(`${endpoint}/login`, {
        email,
        password,
      })
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  civitasLogin({ id, password }) {
    return new Promise((resolve, reject) => {
      Http.post(`${endpoint}/login_civitas`, {
        id,
        password,
      })
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  logout() {
    return new Promise((resolve, reject) => {
      Http.post(`${endpoint}/logout`)
        .then(() => resolve())
        .catch((e) => reject(e));
    });
  },

  addAdmin({ email, name, password, role, description }) {
    return new Promise((resolve, reject) => {
      Http.post(`${endpoint}`, {
        email,
        name,
        password,
        role,
        description,
      })
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },
};
