import React from 'react'
import './Pagination.css'

class Pagination extends React.Component {
  _generatePageNumbers = () => {
    const { activePage, totalPage } = this.props;
    const pageNumbers = [];

    if (totalPage >= 10) {
      let next = activePage + 2;
      const isNearLast = next > totalPage;
      next = isNearLast ? totalPage : next;

      for (let idx = isNearLast ? activePage - 2 : activePage-1; idx < next; idx++) {
        pageNumbers.push(
          <li key={idx} className={activePage === idx+1 ? 'active page-item' : 'page-item'}>
            <a href="#pagination" className="page-link"
               onClick={() => this.props.onPageNumberClick(idx+1)}>
              {idx+1}
            </a>
          </li>
        )
      }

      if (next < totalPage) {
        pageNumbers.push(
          <>
            <li className="disabled page-item">
              <a href="#pagination" className="page-link">
                ...
              </a>
            </li>
            <li className={activePage === totalPage ? 'active page-item' : 'page-item'}>
              <a href="#pagination" className="page-link"
                onClick={() => this.props.onPageNumberClick(totalPage)}>
                {totalPage}
              </a>
            </li>
          </>
        )
      }
    } else { //totalPage < 10
      for (let idx = 0; idx < totalPage; idx++) {
        pageNumbers.push(
          <li key={idx} className={this.props.activePage === idx+1 ? 'active page-item' : 'page-item'}>
            <a href="#pagination" className="page-link"
               onClick={() => this.props.onPageNumberClick(idx+1)}>
              {idx+1}
            </a>
          </li>
        );
      }
    }

    return pageNumbers;
  }

  render() {
    return (
      <nav aria-label="Page navigation example" style={{ display: this.props.totalPage === 0 ? 'none': '' }}>
        <ul className="pagination justify-content-center">
          {
            <li className={this.props.activePage - 5 < 1 ? 'disabled page-item' : 'page-item'}>
              <a href="#pagination" className="page-link" onClick={() => this.props.onPageNumberClick(this.props.activePage - 5)}>
                &laquo;
              </a>
            </li>
          }
          {
            <li className={this.props.activePage === 1 ? 'disabled page-item' : 'page-item'}>
              <a href="#pagination" className="page-link" onClick={this.props.onPreviousClick} tabIndex="-1">Previous</a>
            </li>
          }
          {this._generatePageNumbers()}
          {
            <li className={this.props.activePage === this.props.totalPage ? 'disabled page-item' : 'page-item'}>
              <a href="#pagination" className="page-link" onClick={this.props.onNextClick}>Next</a>
            </li>
          }
          {
            <li className={this.props.activePage + 5 > this.props.totalPage ? 'disabled page-item' : 'page-item'}>
              <a href="#pagination" className="page-link" onClick={() => this.props.onPageNumberClick(this.props.activePage + 5)}>
                &raquo;
              </a>
            </li>
          }
        </ul>
      </nav>
    )
  }
}

export default Pagination;