import React from "react";
import ProtectedRoute from "../ProtectedRoute";
import Pagination from "../../components/pagination/Pagination";
import moment from "moment";
import { Modal, Form, Button } from "react-bootstrap";

import "./Dashboard.css";
import "./ListCivitas.css";

import ApiCivitas from "../../services/api/civitas";
import ApiHistory from "../../services/api/history";

const sortTypes = {
  asc: {
    icon: "fa fa-fw fa-sort-asc",
    value: "asc",
  },
  desc: {
    icon: "fa fa-fw fa-sort-desc",
    value: "desc",
  },
};

class ListCivitas extends React.Component {
  state = {
    currPage: 1,
    perPage: 10,
    totalPage: 0,
    totalCivitas: 0,
    data: [],

    showModal: false,
    detail: {
      selectedName: "",
      selectedID: null,
      sort: sortTypes.asc,
      page: 1,
      perPage: 10,
      totalPage: 1,
      total: 0,
      data: [],
    },

    search: null,
  };

  componentDidMount() {
    this._getListCivitas();
  }

  componentDidUpdate(_, prevState) {
    if (prevState.currPage !== this.state.currPage) {
      this._getListCivitas();
    }

    if (prevState.detail.sort.value !== this.state.detail.sort.value) {
      this._getRespondentHistory();
    }
  }

  _handleNextClick = () => {
    this.setState((prevState) => ({
      currPage: prevState.currPage + 1,
    }));
  };

  _handlePageNumberClick = (num) => {
    this.setState({
      currPage: num,
    });
  };

  _handlePreviousClick = () => {
    this.setState((prevState) => ({
      currPage: prevState.currPage - 1,
    }));
  };

  _openDetailModal = (id, name) => {
    this.setState(
      {
        showModal: true,
        detail: {
          ...this.state.detail,
          selectedID: id,
          selectedName: name,
        },
      },
      this._getRespondentHistory
    );
  };

  _hideDetailModal = () => {
    this.setState({
      showModal: false,
    });
  };

  _getListCivitas = () => {
    const { currPage, perPage, search } = this.state;

    ApiCivitas.getList(currPage, perPage, search).then((res) => {
      const { data } = res;

      this.setState({
        data: data.data || [],
        totalPage: data.total_page,
        totalCivitas: data.total_count,
      });
    });
  };

  _getRespondentHistory = () => {
    const { selectedID, page, perPage } = this.state.detail;
    const { value } = this.state.detail.sort;

    ApiHistory.getRespondentHistory(selectedID, page, perPage, value).then(
      (res) => {
        const { data } = res;

        this.setState({
          detail: {
            ...this.state.detail,
            data: data.history,
            totalPage: data.total_pages,
          },
        });
      }
    );
  };

  _searchCivitas = (e) => {
    e.preventDefault();
    this._getListCivitas();
  };

  _renderSearchForm = () => (
    <Form inline onSubmit={this._searchCivitas}>
      <Form.Group className="w-100">
        <Form.Control
          type="text"
          placeholder="Masukkan nomor identitas atau nama"
          style={{ width: 400 }}
          onChange={(event) => this.setState({ search: event.target.value })}
        />
        <Button variant="primary" type="submit" className="ml-3">
          Cari Civitas
        </Button>
      </Form.Group>
    </Form>
  );

  _renderDetailModal = () => (
    <Modal
      className="custom-modal"
      show={this.state.showModal}
      onHide={this._hideDetailModal}
    >
      <Modal.Header closeButton>
        <h4>
          Detail Historis - {this.state.detail.selectedName} -{" "}
          {this.state.detail.selectedID}
        </h4>
      </Modal.Header>
      <Modal.Body>
        <table
          className="table table-responsive table-hover ml-3 mt-3"
          style={{
            display: "block",
            overflowX: "auto",
            overflowY: "auto",
            width: "95%",
            height: "58vh",
          }}
        >
          <thead className="table-primary">
            <th scope="col">
              Tanggal
              <i
                className={this.state.detail.sort.icon}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  const currValue = this.state.detail.sort.value;
                  let valueNow;

                  if (currValue === "asc") valueNow = "desc";
                  else valueNow = "asc";

                  this.setState({
                    detail: {
                      ...this.state.detail,
                      sort: sortTypes[valueNow],
                    },
                  });
                }}
              ></i>
            </th>
            <th scope="col">Nama</th>
            <th scope="col">NIM/NIDN/NIDK/NIP/No. SPKWT/SK</th>
            <th scope="col">No. Hp</th>
            <th scope="col">Ada Gejala?</th>
            <th scope="col">Kondisi Fisik?</th>
            <th scope="col">Kabupaten/Kota Keluar</th>
          </thead>
          <tbody>
            {this.state.detail.data.map((data, idx) => (
              <tr key={idx}>
                <td>{moment(data.timestamp).format("DD/MM/YYYY HH:mm")}</td>
                <td>{this.state.detail.selectedName}</td>
                <td>{this.state.detail.selectedID}</td>
                <td>
                  <ul className="list-group">
                    {data.contacts.map((contact) => (
                      <li key={contact} className="list-group-item">{contact}</li>
                    ))}
                  </ul>
                </td>
                <td>{data.has_symptoms ? "Ya" : "Tidak"}</td>
                <td>{data.good_condition ? "Baik" : "Kurang Baik"}</td>
                <td>{data.destination || "-"}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          activePage={this.state.detail.page}
          totalPage={this.state.detail.totalPage}
          onPreviousClick={() => {
            this.setState(
              (prevState) => ({
                detail: {
                  ...prevState.detail,
                  page: prevState.detail.page - 1,
                },
              }),
              this._getRespondentHistory
            );
          }}
          onPageNumberClick={(num) => {
            this.setState(
              (prevState) => ({
                detail: {
                  ...prevState.detail,
                  page: num,
                },
              }),
              this._getRespondentHistory
            );
          }}
          onNextClick={() => {
            this.setState(
              (prevState) => ({
                detail: {
                  ...prevState.detail,
                  page: prevState.detail.page + 1,
                },
              }),
              this._getRespondentHistory
            );
          }}
        />
      </Modal.Body>
    </Modal>
  );

  render() {
    const { data, totalCivitas, perPage, currPage, totalPage } = this.state;

    return (
      <ProtectedRoute>
        {this._renderDetailModal()}
        <div className="container-dashboard">
          <div className="row">
            <div className="col-sm-12 p-4">
              {/* Table */}
              <div className="row justify-content-between mb-1">
                <div className="col-auto text-left d-flex align-items-center">
                  <h1 className="m-0">List of Civitas</h1>
                </div>
                <div className="col-auto">{this._renderSearchForm()}</div>
              </div>
              <div className="row">
                <table className="table table-hover ml-3 mt-3">
                  <caption>
                    {(currPage - 1) * perPage + data.length} of {totalCivitas}{" "}
                    total data
                    <Pagination
                      id={"table-list-civitas"}
                      activePage={currPage}
                      totalPage={totalPage}
                      onPreviousClick={this._handlePreviousClick}
                      onPageNumberClick={(num) =>
                        this._handlePageNumberClick(num)
                      }
                      onNextClick={this._handleNextClick}
                    />
                  </caption>
                  <thead className="table-primary">
                    <tr>
                      <th scope="col-auto">NIM/NIDN/NIDK/NIP/No. SPKWT/SK</th>
                      <th scope="col">Nama</th>
                      <th scope="col">Usia</th>
                      <th scope="col">Jenis Civitas</th>
                      <th scope="col">Fakultas</th>
                      <th scope="col">Prodi</th>
                      <th scope="col">No. Hp</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((d) => (
                      <tr
                        key={d.id}
                        onClick={() => this._openDetailModal(d.id, d.name)}
                        style={{ cursor: "pointer" }}
                      >
                        <th scope="row">{d.id}</th>
                        <td>{d.name}</td>
                        <td>{d.age}</td>
                        <td>{d.type}</td>
                        <td>
                          {d.department === null ? "-" : d.department.faculty}
                        </td>
                        <td>
                          {d.department === null ? "-" : d.department.name}
                        </td>
                        <td>
                          <ul className="list-group">
                            {d.phones.map((phone) => (
                              <li key={phone} className="list-group-item">{phone}</li>
                            ))}
                          </ul>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </ProtectedRoute>
    );
  }
}

export default ListCivitas;
