import React from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import Workbook from 'react-excel-workbook';
import Pagination from "../../../../components/pagination/Pagination";
import { mapKeyToCol } from '../../../../utils/mappingcol';

import EditCompletedRecordModal from '../edit';
import { Alert } from 'react-bootstrap';

import ApiReport from "../../../../services/api/report";

class NonCompletedTable extends React.Component {
  state = {
    uncompleted: {
      startTime: moment({ year: 2020, month: 2, day: 28 }).format("X"),
      endTime: moment().format("X"),
      startTimeText: moment({ year: 2020, month: 2, day: 28 }).format(
        "DD/MM/YYYY"
      ),
      endTimeText: moment().format("DD/MM/YYYY"),
      page: 1,
      perPage: 10,
      totalPages: 0,
      respondentTypes: ["All"],
      departmentIds: ["All"],
      status: ["All"],
      sort: {
        icon: "fa fa-fw fa-sort-asc",
        value: "asc",
      },
      data: [],
    },

    //Table data for download purpose
    tableData: {
      uncompleted: {
        data: [],
        downloadable: false,
      },
    },

    sort: {
      asc: {
        icon: "fa fa-fw fa-sort-asc",
        value: "asc",
      },
      desc: {
        icon: "fa fa-fw fa-sort-desc",
        value: "desc",
      },
    },

    showModal: false, 
    waitForEdit: false,
    errorOnEdit: false,
    successOnEdit: false,
    alertMessage: '', 
    dataToEdit: {
      name: '',
      id: '',
    }
  }

  componentDidMount() {
    this._getFilteredUncompletedReport();
    this._getWorkSheet('uncompleted');
  }

  componentDidUpdate(_, prevState) {
    if (prevState.uncompleted.startTime !== this.state.uncompleted.startTime) {
      this._getFilteredUncompletedReport();
      this._getWorkSheet('uncompleted');
    }
    if (
      prevState.uncompleted.sort.value !== this.state.uncompleted.sort.value
    ) {
      this._getFilteredUncompletedReport();
      this._getWorkSheet('uncompleted');
    }
  }

  _handleApplyDate = (_, picker) => {
    this.setState({
      uncompleted: {
        ...this.state.uncompleted,
        startTime: moment(picker.startDate).format("X"),
        endTime: moment(picker.endDate).format("X"),
        startTimeText: moment(picker.startDate).format("DD/MM/YYYY"),
        endTimeText: moment(picker.endDate).format("DD/MM/YYYY"),
      },
    });
  };

  _getWorkSheet = (type) => {
    const {
      startTime,
      endTime,
      respondentTypes,
      departmentIds,
      status,
      sort,
    } = this.state[type];

    const filter = {
      date_end: moment(endTime, "X").format("YYYY-MM-DD"),
      date_start: moment(startTime, "X").format("YYYY-MM-DD"),
      department_ids: departmentIds || ['All'],
      is_completed: type === 'completed' ? true : false,
      mode: "detail",
      respondent_types: respondentTypes || ['All'],
      status: status || ['All'],
    };

    this.setState({
      tableData: {
        ...this.state.tableData,
        [type]: {
          ...this.state.tableData[type],
          downloadable: false,
        }
      }
    })

    ApiReport.getFilteredReportWithPagination(
      filter,
      1,
      9999,
      sort.value
    )
      .then((res) => {
        const { data } = res;

        //Convert array to string
        data.respondents.forEach((obj) => {
          obj['contacts'] = obj['contacts'].toString();
        });

        this.setState({
          tableData: {
            ...this.state.tableData,
            [type]: {
              data: data.respondents,
              downloadable: true,
            }
          }
        })
      });
  }

  _hideEditModal = (succ) => {
    if (typeof(succ) === "undefined") {
      this.setState({
        showModal: false,
      });
      return;
    }

    let obj = {};
    if (succ) {
      obj = {
        successOnEdit: true,
        alertMessage: 'Data berhasil diperbarui!',
      }
    } else {
      obj = {
        errorOnEdit: true,
        alertMessage: 'Data gagal diperbarui!',
      }
    }

    this.setState({
      ...this.state,
      ...obj,
      showModal: false,
      waitForEdit: false,
    }, () => {
      if (succ) {
        this._getFilteredUncompletedReport();
        this._getWorkSheet('uncompleted');
      }
    });
  }

  _openEditModal = (data) => {
    this.setState({
      dataToEdit: data,
      showModal: true
    });
  }

  _handleAlertClose = () => {
    this.setState({
      ...this.state,
      errorOnEdit: false,
      successOnEdit: false
    });
  }

  _getFilteredUncompletedReport = () => {
    const {
      startTime,
      endTime,
      page,
      perPage,
      sort,
    } = this.state.uncompleted;

    const filter = {
      date_end: moment(endTime, "X").format("YYYY-MM-DD"),
      date_start: moment(startTime, "X").format("YYYY-MM-DD"),
      is_completed: false,
      department_ids: ['All'],
      respondent_types: ['All'],
      mode: "detail",
      status: ['All'],
    };

    ApiReport.getFilteredReportWithPagination(
      filter,
      page,
      perPage,
      sort.value
    )
      .then((res) => {
        const { data } = res;

        //Convert array to string
        data.respondents.forEach((obj) => {
          obj['contacts'] = obj['contacts'].toString();
        })

        this.setState({
          uncompleted: {
            ...this.state.uncompleted,
            data: data.respondents,
            totalPages: data.total_pages,
          },
        });
      })
      .catch((e) => {
        if (e.response.status === 404) {
          this.setState({
            uncompleted: {
              ...this.state.uncompleted,
              data: [],
            },
          });
        }
      });
  };

  render() {
    const { uncompleted, errorOnEdit, successOnEdit, alertMessage } = this.state;
    const { filter } = this.props;

    return (
      <div className="row my-3 pt-3">
        <Alert
          show={errorOnEdit || successOnEdit}
          className="alert-custom"
          variant={errorOnEdit ? 'danger': 'success' }>
          {alertMessage}
          <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this._handleAlertClose}>
            <span aria-hidden="true">&times;</span>
          </button>
        </Alert>
        {
          this.state.showModal &&
          <EditCompletedRecordModal
            filter={filter}
            loading={this.state.waitForEdit}
            show={this.state.showModal}
            data={this.state.dataToEdit}
            onHide={this._hideEditModal}
            onSubmit={(data) => {
                this.setState({
                  waitForEdit: true,
                });

                data['id'] = data['civitas_id'];
                ApiReport.updateReport(data, data.report_id)
                  .then(() => {
                    this._hideEditModal(true);
                  })
                  .catch((_) => {
                    this._hideEditModal(false);
                  });
              }}
            />
        }
        <div className="col text-left">
          <h2>Daftar Responden "Non Completed"</h2>
        </div>
        <div className="col-12 mt-2 text-left">
          <div className="row">
            <div className="col-12 my-2">Filter Data</div>
            <div className="col">
              <DateRangePicker
                onApply={(e, picker) =>
                  this._handleApplyDate(e, picker)
                }
                maxDate={moment().format("MM/DD/YYYY")}
              >
                <form className="form-inline">
                  <div className="form-group mb-2" style={{ width: '27%' }}>
                    <input
                      type="text"
                      style={{
                        width: '100%'
                      }}
                      className="form-control"
                      name="date-input"
                      placeholder="Start Date"
                      value={uncompleted.startTimeText}
                    />
                  </div>
                  <p className="mt-2 mx-3">s/d</p>
                  <div className="form-group mb-2" style={{ width: '27%' }}>
                    <input
                      type="text"
                      style={{
                        width: '100%'
                      }}
                      className="form-control"
                      name="date-input"
                      placeholder="End Date"
                      value={uncompleted.endTimeText}
                    />
                  </div>
                </form>
              </DateRangePicker>
            </div>
            <div className="mx-2" style={{ width: "15%" }}>
              <Workbook filename={`AMARI_Covid19-NonCompleted-${moment().format('X')}.xlsx`} element={
                <button className="btn btn-primary ml-3 pull-right" disabled={!this.state.tableData.uncompleted.downloadable}>Download as XLS</button>
              }>
                {
                  this.state.tableData.uncompleted.data.length > 0 && (
                    <Workbook.Sheet data={this.state.tableData.uncompleted.data} name="Sheet1" >
                      {
                        Object.keys(this.state.tableData.uncompleted.data[0]).map((key) => {
                          return (
                            <Workbook.Column key={key} label={mapKeyToCol(key)} value={key} />
                          )
                        })
                      }
                    </Workbook.Sheet>
                  )
                }
              </Workbook>
            </div>
          </div>
        </div>
        <table className="table table-responsive table-hover ml-3 mt-3">
          <caption>
            <Pagination
              id={"table-response-incomplete"}
              activePage={uncompleted.page}
              totalPage={uncompleted.totalPages}
              onPreviousClick={() => {
                this.setState((prevState) => ({
                  uncompleted: {
                    ...prevState.uncompleted,
                    page: prevState.uncompleted.page - 1,
                  },
                }), this._getFilteredUncompletedReport);
              }}
              onPageNumberClick={(num) => {
                this.setState((prevState) => ({
                  uncompleted: {
                    ...prevState.uncompleted,
                    page: num,
                  },
                }), this._getFilteredUncompletedReport);
              }}
              onNextClick={() => {
                this.setState((prevState) => ({
                  uncompleted: {
                    ...prevState.uncompleted,
                    page: prevState.uncompleted.page + 1,
                  },
                }), this._getFilteredUncompletedReport);
              }}
            />
          </caption>
          <thead className="table-primary">
            <tr>
              <th scope="col">
                Tanggal
                <i
                  className={this.state.uncompleted.sort.icon}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    const currValue = this.state.uncompleted.sort.value;
                    let valueNow;

                    if (currValue === "asc") valueNow = "desc";
                    else valueNow = "asc";

                    this.setState({
                      uncompleted: {
                        ...this.state.uncompleted,
                        sort: this.state.sort[valueNow],
                      },
                    });
                  }}
                ></i>
              </th>
              <th scope="col">Nama</th>
              <th scope="col">Usia</th>
              <th scope="col">No. Hp</th>
              <th scope="col">Jenis Responden</th>
              <th scope="col">NIM/NIDN/NIDK/NIP/No. SPKWT/SK</th>
              <th scope="col">Prodi</th>
              <th scope="col">Ada Gejala?</th>
              <th scope="col">Kondisi Fisik</th>
              <th scope="col">Status</th>
              <th scope="col">Jenis Tmp Tgl</th>
              <th scope="col">Kabupaten/Kota Keluar</th>
              <th scope="col">Kecamatan</th>
              <th scope="col">Kota</th>
            </tr>
          </thead>
          <tbody>
            {uncompleted.data.length === 0 && (
              <tr>
                <td colSpan="13">No data</td>
              </tr>
            )}
            {uncompleted.data.map((data, idx) => (
              <tr key={idx} onClick={() => this._openEditModal(data)} style={{ cursor: "pointer" }}>
                <th scope="row">
                  {moment(data.timestamp).format("DD/MM/YYYY HH:mm")}
                </th>
                <td>{data.name || "-"}</td>
                <td>{data.age || "-"}</td>
                <td>
                  <ul className="list-group">
                    {data.contacts.split(',').map((contact, idx) => (
                      <li key={idx} className="list-group-item">{contact}</li>
                    ))}
                  </ul>
                </td>
                <td>{data.type || "-"}</td>
                <td>{data.id || "-"}</td>
                <td>{data.department_name || "-"}</td>
                <td>{data.has_symptoms ? "Ya" : "Tidak"}</td>
                <td>{data.good_condition ? "Baik" : "Kurang Baik"}</td>
                <td>{data.status || "-"}</td>
                <td>{data.residence_type || "-"}</td>
                <td>{data.destination || "-"}</td>
                <td>{data.district || "-"}</td>
                <td>{data.regency || "-"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
}

export default NonCompletedTable;