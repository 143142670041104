import { Http } from "../http";

const endpoint = "civitas";

export default {
  getList(page = 1, perPage = 15, search) {
    const params = [`page=${page}`, `perPage=${perPage}`];
    if (search) params.push(`search=${search}`);

    return new Promise((resolve, reject) => {
      Http.get(`${endpoint}?${params.join("&")}`)
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },
  updateCivitas(civitas, civitasId) {
    return new Promise((resolve, reject) => {
      Http.put(`${endpoint}/${civitasId}`, civitas)
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    })
  }
};
