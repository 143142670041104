import React from "react";
import dayjs from "dayjs";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Select from "react-select";
import { HorizontalBar } from "react-chartjs-2";

import ApiAttrbs from "../../../services/api/attributes";
import ApiReport from "../../../services/api/report";

class CompletedSummary extends React.Component {
  state = {
    completedData1: {
      respondents: null,
      summary: {},
      daily_summary: {},
    },
    completedData2: {
      respondents: null,
      summary: {},
      daily_summary: {},
    },
    filterData: {
      respondentTypes: [],
      departments: [],
    },
    filter: {
      startDate: "2020-03-28",
      endDate: dayjs().format("YYYY-MM-DD"),
      respondentTypes: ["All"],
      departments1: ["All"],
      tag1: "All",
      departments2: ["All"],
      tag2: "All",
    },
  };

  componentDidMount() {
    this._getFilterAttributes();
    this._getFilteredCompletedSummary();
  }

  _getFilteredCompletedSummary = () => {
    const {
      startDate,
      endDate,
      respondentTypes,
      departments1,
      departments2,
    } = this.state.filter;

    const filter1 = {
      date_end: endDate,
      date_start: startDate,
      department_ids: departments1,
      is_completed: true,
      mode: "summary",
      respondent_types: respondentTypes,
      status: ["All"],
    };
    const filter2 = {
      date_end: endDate,
      date_start: startDate,
      department_ids: departments2,
      is_completed: true,
      mode: "summary",
      respondent_types: respondentTypes,
      status: ["All"],
    };

    ApiReport.getFilteredReport(filter1).then((res) => {
      this.setState({ completedData1: res.data });
    });
    ApiReport.getFilteredReport(filter2).then((res) => {
      this.setState({ completedData2: res.data });
    });
  };

  _getFilterAttributes = () => {
    ApiAttrbs.getRespondentTypes().then((res) => {
      this.setState({
        filterData: {
          ...JSON.parse(JSON.stringify(this.state.filterData)),
          respondentTypes: res.data.respondent_types,
        },
      });
    });

    ApiAttrbs.getDepartments().then((res) => {
      this.setState({
        filterData: {
          ...JSON.parse(JSON.stringify(this.state.filterData)),
          departments: res.data.departments,
        },
      });
    });
  };

  _getCompleteDailyChartProps = () => {
    const data1 = this.state.completedData1.summary.total;
    const data2 = this.state.completedData2.summary.total;
    const { tag1, tag2 } = this.state.filter;

    return {
      data: {
        labels: [tag1, tag2],
        datasets: [
          {
            label: 'Total respondents',
            data: [data1, data2],
            backgroundColor: ["#3346D1", "#D50000"],
          },/* 
          {
            label: departments2,
            data: data2,
            backgroundColor: "#D50000",
          }, */
        ],
      },
      legend: {
        display: false,
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      },
    };
  };

  _renderCompletedFilter = () => {
    return (
      <div
        className="col"
        style={{
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <div style={{ width: "45%" }}>
          <label>Tipe Responden</label>
          <Select
            isMulti
            onChange={(newValue) => {
              let respondentTypes;
              if (!newValue || newValue.length === 0) respondentTypes = ["All"];
              else respondentTypes = newValue.map((value) => value.value);

              this.setState(
                {
                  filter: {
                    ...JSON.parse(JSON.stringify(this.state.filter)),
                    respondentTypes,
                  },
                },
                this._getFilteredCompletedSummary
              );
            }}
            options={this.state.filterData.respondentTypes.map((type) => ({
              value: type,
              label: type,
            }))}
          />
        </div>
        <div style={{ width: "45%" }}>
          <label>Program Studi</label>
          <Select
            isMulti
            onChange={(newValue) => {
              let tag1;
              let departments1;
              if (!newValue || newValue.length === 0) {
                tag1 = "All";
                departments1 = ["All"];
              }
              else {
                departments1 = newValue.map((value) => value.value);
                tag1 = newValue.map((value) => value.label);
              }

              this.setState(
                {
                  filter: {
                    ...JSON.parse(JSON.stringify(this.state.filter)),
                    departments1,
                    tag1,
                  },
                },
                this._getFilteredCompletedSummary
              );
            }}
            options={this.state.filterData.departments.map((department) => ({
              value: department.id,
              label: department.name,
            }))}
          />
        </div>
        <div style={{ width: "45%" }}>
          <label>Program Studi</label>
          <Select
            isMulti
            onChange={(newValue) => {
              let tag2;
              let departments2;
              if (!newValue || newValue.length === 0) {
                tag2 = "All";
                departments2 = ["All"];
              }
              else {
                departments2 = newValue.map((value) => value.value);
                tag2 = newValue.map((value) => value.label);
              }

              this.setState(
                {
                  filter: {
                    ...JSON.parse(JSON.stringify(this.state.filter)),
                    departments2,
                    tag2,
                  },
                },
                this._getFilteredCompletedSummary
              );
            }}
            options={this.state.filterData.departments.map((department) => ({
              value: department.id,
              label: department.name,
            }))}
          />
        </div>
      </div>
    );
  };

  _renderCompletedDateRangePicker = () => {
    return (
      <div
        className="col"
        style={{
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <DateRangePicker
          onApply={(e, picker) => {
            this.setState(
              {
                filter: {
                  ...JSON.parse(JSON.stringify(this.state.filter)),
                  startDate: dayjs(picker.startDate).format("YYYY-MM-DD"),
                  endDate: dayjs(picker.endDate).format("YYYY-MM-DD"),
                },
              },
              this._getFilteredCompletedSummary
            );
          }}
          startDate={dayjs(this.state.filter.startDate).format("MM/DD/YYYY")}
          endDate={dayjs(this.state.filter.endDate).format("MM/DD/YYYY")}
          maxDate={dayjs().format("MM/DD/YYYY")}
        >
          <form className="form-inline">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="date-input"
                placeholder="Start Date"
                value={dayjs(this.state.filter.startDate)
                  .locale("id")
                  .format("DD MMMM YYYY")}
              />
            </div>
            <label className="mx-3">sampai</label>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="date-input"
                placeholder="End Date"
                value={dayjs(this.state.filter.endDate)
                  .locale("id")
                  .format("DD MMMM YYYY")}
              />
            </div>
          </form>
        </DateRangePicker>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="row mb-3">
          <div
            className="col-4 text-left"
            style={{ display: "flex", alignItems: "flex-end" }}
          ></div>
          {this._renderCompletedFilter()}
        </div>
        <div className="row mb-3">
          <div
            className="col-4 text-left"
            style={{ display: "flex", alignItems: "flex-end" }}
          >
            <h2>Statistik per Program Studi</h2>
          </div>
          {/* {this._renderCompletedDateRangePicker()} */}
        </div>
        <div className="row mb-4">
          <div className="cards-statistic col">
            <HorizontalBar {...this._getCompleteDailyChartProps()} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CompletedSummary;
