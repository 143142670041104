import axios from "axios";

const BaseUrl = process.env.REACT_APP_BASE_URL;

export const Http = {
  async init() {
    // Need to updated base on url
    axios.defaults.baseURL = BaseUrl;

    const token = await localStorage.getItem("CV_ITB_TOKEN");

    // Intercept the request to make sure the token is injected into the header.
    await axios.interceptors.request.use((config) => {
      // we intercept axios request and add authorizatio header before perform send a request to the server
      // eslint-disable-next-line no-param-reassign
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });

    // Intercept the response and…
    await axios.interceptors.response.use(
      async (response) => {
        // …get the token from the header or response data if exists, and save it.
        const token =
          response.headers.Authorization || response.data.access_token;
        const user = response.data.account;

        token && (await localStorage.setItem("CV_ITB_TOKEN", token));
        user &&
          (await localStorage.setItem("CV_ITB_USER", JSON.stringify(user)));

        return response;
      },
      async (error) => {
        // Also, if we receive a Bad Request / Unauthorized error
        if (error.response.status === 400) {
        }
        // Unauthorized error
        else if (error.response.status === 401) {
          // const store = await createStore(reducers);
          // await store.dispatch({
          //   type: types.USER_LOGOUT,
          // });

          // hack
          if (localStorage.getItem("CV_ITB_ROLE") !== "Civitas") {
            await localStorage.removeItem("CV_ITB_TOKEN");
            await localStorage.removeItem("CV_ITB_USER");
          }
        } else if (error.response.status === 500) {
        }

        return Promise.reject(error);
      }
    );
  },

  async request(
    method,
    url,
    data,
    successCb = null,
    errorCb = null,
    headers = {}
  ) {
    await this.init();

    // const apiData = data instanceof FormData ? data : qs.stringify(data);

    const response = axios
      .request({
        baseURL: BaseUrl,
        url,
        data,
        method,
        headers: Object.assign(
          {
            "Content-Type": "application/json",
            // 'X-Requested-With': 'XMLHttpRequest',
          },
          headers
        ),
      })
      .then(successCb)
      .catch(errorCb);

    return response;
  },

  get(url, data, successCb = null, errorCb = null) {
    return this.request("GET", url, data, successCb, errorCb, {});
  },

  post(url, data, successCb = null, errorCb = null, headers = {}) {
    return this.request("POST", url, data, successCb, errorCb, headers);
  },

  postUpload(
    url,
    data,
    successCb = null,
    errorCb = null,
    headers = {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    }
  ) {
    return this.request("POST", url, data, successCb, errorCb, headers);
  },

  patch(url, data, successCb = null, errorCb = null, headers = {}) {
    return this.request("PATCH", url, data, successCb, errorCb, headers);
  },

  put(url, data, successCb = null, errorCb = null, headers = {}) {
    return this.request("PUT", url, data, successCb, errorCb, headers);
  },

  delete(url, data, successCb = null, errorCb = null, headers = {}) {
    return this.request("DELETE", url, data, successCb, errorCb, headers);
  },
};
