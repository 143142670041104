import React from "react";
import { Modal } from "react-bootstrap";

class EditCompletedRecordModal extends React.Component {
  state = {
    data: {
      ...this.props.data,
      civitas_id: this.props.data.id,
      contacts: this.props.data["contacts"].split(","),
    },
  };

  _handleAddPhone = () => {
    const { contacts } = this.state.data;
    contacts.push("");

    this.setState({
      data: {
        ...this.state.data,
        contacts,
      },
    });
  };

  _handleDeletePhone = (idx) => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        contacts: prevState.data["contacts"].filter((_, i) => i !== idx),
      },
    }));
  };

  _onValueChanged = (e) => {
    const { id, name, value } = e.target;

    if (name.includes("contacts")) {
      const { contacts } = this.state.data;
      const idx = name.split(".")[1];

      contacts[Number(idx)] = value;
      this.setState({
        data: {
          ...this.state.data,
          contacts,
        },
      });
    } else {
      const isValueBool = id === "has_symptoms" || id === "good_condition";
      const val = isValueBool ? value === "true" : value;

      this.setState({
        data: {
          ...this.state.data,
          [id]: val,
        },
      });
    }
  };

  render() {
    const { onHide, show, filter, loading, onSubmit } = this.props;
    const { data } = this.state;

    return (
      <Modal className="custom-modal" onHide={onHide} show={show}>
        <Modal.Header closeButton>
          <h4>
            Edit Record - {this.props.data.name} - {this.props.data.id}
          </h4>
        </Modal.Header>
        <Modal.Body>
          <form
            className="m-3 p-2"
            style={{
              overflowY: "visible",
              overflowX: "hidden",
              maxHeight: "60vh",
            }}
          >
            <div className="row">
              <div className="col-6 form-group">
                <label className="my-1 mr-2" htmlFor="name">
                  Nama
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Masukan nama"
                  value={data.name}
                  onChange={this._onValueChanged}
                />
              </div>
              <div className="col-6 form-group">
                <label className="my-1 mr-2" htmlFor="age">
                  Usia
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="age"
                  min="0"
                  placeholder="Masukan usia"
                  value={data.age}
                  onChange={this._onValueChanged}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6 form-group">
                <label className="my-1 mr-2" htmlFor="contacts">
                  No. Hp
                </label>
                <ul class="list-group">
                  {data["contacts"] &&
                    data["contacts"].map((obj, idx) => (
                      <li
                        key={idx}
                        class="list-group-item d-flex justify-content-between align-items-center"
                      >
                        <input
                          type="text"
                          className="form-control"
                          name={"contacts." + idx}
                          value={obj}
                          placeholder="Masukan no. hp"
                          onChange={this._onValueChanged}
                        />
                        <span className="pull-right ml-2">
                          <div
                            className="btn btn-danger"
                            onClick={() => this._handleDeletePhone(idx)}
                          >
                            <i className="fa fa-trash" />
                          </div>
                        </span>
                      </li>
                    ))}
                  <li class="list-group-item">
                    <div
                      className="btn btn-success btn-block"
                      onClick={this._handleAddPhone}
                    >
                      <i className="fa fa-plus mr-2" />
                      Tambah
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-6 form-group">
                <label className="my-1 mr-2" htmlFor="type">
                  Jenis Responden
                </label>
                <select
                  className="form-control"
                  id="type"
                  onChange={this._onValueChanged}
                >
                  <option value="-" defaultChecked>
                    -
                  </option>
                  {filter["respondentTypes"].map((obj, idx) => (
                    <option key={idx} value={obj} selected={obj === data.type}>
                      {obj}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-6 form-group">
                <label className="my-1 mr-2" htmlFor="civitas_id">
                  NIM/NIDN/NIDK/NIP/No. SPKWT/SK
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="civitas_id"
                  placeholder="Masukan nomor identitas"
                  value={data.civitas_id}
                  onChange={this._onValueChanged}
                />
              </div>
              <div className="col-6 form-group">
                <label className="my-1 mr-2" htmlFor="department_name">
                  Prodi
                </label>
                <select
                  className="form-control"
                  id="department_name"
                  onChange={this._onValueChanged}
                >
                  {filter["majors"].map((obj) => (
                    <option
                      key={obj.id}
                      value={obj.name}
                      selected={obj.name === data.department_name}
                    >
                      {obj.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-4 form-group">
                <label className="my-1 mr-2" htmlFor="has_symptoms">
                  Ada Gejala?
                </label>
                <select
                  className="form-control"
                  id="has_symptoms"
                  onChange={this._onValueChanged}
                >
                  <option value={true} selected={data.has_symptoms === true}>
                    Ya
                  </option>
                  <option value={false} selected={data.has_symptoms === false}>
                    Tidak
                  </option>
                </select>
              </div>
              <div className="col-4 form-group">
                <label className="my-1 mr-2" htmlFor="good_condition">
                  Kondisi Fisik
                </label>
                <select
                  className="form-control"
                  id="good_condition"
                  onChange={this._onValueChanged}
                >
                  <option value={true} selected={data.good_condition === true}>
                    Baik
                  </option>
                  <option
                    value={false}
                    selected={data.good_condition === false}
                  >
                    Kurang Baik
                  </option>
                </select>
              </div>
              <div className="col-4 form-group">
                <label className="my-1 mr-2" htmlFor="status">
                  Status
                </label>
                <select
                  className="form-control"
                  id="status"
                  onChange={this._onValueChanged}
                >
                  {filter["status"].map((obj) => (
                    <option
                      key={obj}
                      value={obj}
                      selected={obj === data.status}
                    >
                      Kasus {obj}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-6 form-group">
                <label className="my-1 mr-2" htmlFor="residence_type">
                  Jenis Tmp Tgl
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="residence_type"
                  value={data.residence_type}
                  onChange={this._onValueChanged}
                  placeholder="Masukan jenis tempat tinggal"
                />
              </div>
              <div className="col-6 form-group">
                <label className="my-1 mr-2" htmlFor="destination">
                  Kabupaten/Kota Keluar
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="destination"
                  value={data.destination}
                  onChange={this._onValueChanged}
                  placeholder="Masukan kabupaten/kota keluar"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6 form-group">
                <label className="my-1 mr-2" htmlFor="district">
                  Kecamatan
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="district"
                  value={data.district}
                  onChange={this._onValueChanged}
                  placeholder="Masukan kecamatan saat ini"
                />
              </div>
              <div className="col-6 form-group">
                <label className="my-1 mr-2" htmlFor="regency">
                  Kota
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="regency"
                  value={data.regency}
                  onChange={this._onValueChanged}
                  placeholder="Masukan kota saat ini"
                />
              </div>
            </div>
          </form>
          <button
            type="submit"
            className="mr-3 my-auto btn btn-primary pull-right"
            onClick={() => onSubmit(data)}
            disabled={loading}
          >
            {loading ? "Updating.." : "Update"}
          </button>
        </Modal.Body>
      </Modal>
    );
  }
}

export default EditCompletedRecordModal;
