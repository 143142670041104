import React from "react";
import dayjs from "dayjs";
import { Pie, Line } from "react-chartjs-2";

import ApiReport from "../../../services/api/report";

class TotalSummary extends React.Component {
  state = {
    completenessTotal: { complete: 0, incomplete: 0 },
    completenessRange: [],
  };

  componentDidMount() {
    this._getCompletenessTotal();
    this._getCompletenessRange();
  }

  _getCompletenessTotal = () => {
    ApiReport.getCompletenessTotal().then((res) => {
      this.setState({ completenessTotal: res.data[0] });
    });
  };

  _getCompletenessRange = () => {
    const endTime = dayjs().unix();

    ApiReport.getCompletenessRange({ endTime }).then((res) => {
      this.setState({ completenessRange: res.data });
    });
  };

  _getCompletenessReportChartProps = () => {
    const { complete, incomplete } = this.state.completenessTotal;

    return {
      data: {
        labels: ["Complete", "Incomplete"],
        datasets: [
          {
            data: [complete, incomplete],
            backgroundColor: ["#3346D1", "#D50000"],
            borderWidth: 0,
          },
        ],
      },
      legend: {
        position: "bottom",
        labels: {
          padding: 15,
        },
      },
      options: {
        title: {
          display: true,
          text: `Total: ${complete + incomplete}`,
          fontSize: 24,
          fontColor: "#212121",
        },
        maintainAspectRatio: false,
        plugins: {
          labels: {
            fontColor: "#FAFAFA",
            precision: 1,
          },
        },
      },
    };
  };

  _getCompletenessStatisticsProps = () => {
    const date = [];
    const complete = [];
    const incomplete = [];

    this.state.completenessRange.forEach((report) => {
      date.push(dayjs(report.date).locale("id").format("DD MMMM YYYY"));
      complete.push(report.complete);
      incomplete.push(report.incomplete);
    });

    return {
      data: {
        labels: date,
        datasets: [
          {
            label: "Complete",
            data: complete,
            fill: false,
            borderColor: "#3346D1",
          },
          {
            label: "Incomplete",
            data: incomplete,
            fill: false,
            borderColor: "#D50000",
          },
        ],
      },
      legend: {
        position: "bottom",
      },
      options: {
        maintainAspectRatio: false,
      },
    };
  };

  render() {
    return (
      <React.Fragment>
        <div className="row mb-3">
          <div className="col text-left">
            <h2>Statistik Keseluruhan</h2>
          </div>
        </div>
        <div className="row mb-4">
          <div className="cards-statistic col-3">
            <Pie {...this._getCompletenessReportChartProps()} />
          </div>
          <div className="cards-statistic col">
            <Line {...this._getCompletenessStatisticsProps()} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TotalSummary;
