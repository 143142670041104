import { Http } from "../http";

export default {
  getRespondentTypes() {
    return new Promise((resolve, reject) => {
      Http.get('respondent-types')
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },
  getDepartments() {
    return new Promise((resolve, reject) => {
      Http.get('departments')
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },
};
