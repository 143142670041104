import React from "react";
import SideNav, { NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav";

// Be sure to include styles at some point, probably during your bootstraping
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import navigations from "../../_nav";
import "./SideBar.css";

class SideBar extends React.Component {
  _renderChildren = (it) => {
    if (typeof it === "object" && it.hasOwnProperty("children")) {
      const items = it["children"];
      return items.map((o, idx) => (
        <NavItem key={idx} eventKey={o.url}>
          <NavIcon>
            <i
              className={o.icon}
              style={{ fontSize: "1.75rem", color: "#000" }}
            />
          </NavIcon>
          <NavText style={{ color: "black" }}>{o.name}</NavText>
          {this._renderChildren(o)}
        </NavItem>
      ));
    }

    return null;
  };

  render() {
    const { location, history } = this.props;
    const items = localStorage.getItem("CV_ITB_ROLE") === "Civitas" ? navigations["civitas"] : navigations["items"];

    return (
      <SideNav
        style={{
          backgroundColor: "#ECF5FE",
          border: "16px solid #ECF5FE",
          position: "fixed",
        }}
        onSelect={(selected) => {
          const to = "/" + selected;
          if (location.pathname !== to) {
            history.push(to);
          }
        }}
        onToggle={this.props.onToggle}
      >
        <SideNav.Toggle
          style={{
            borderColor: "#000",
          }}
        />
        <SideNav.Nav defaultSelected="/dashboard">
          {items.map((it, idx) => (
            <NavItem
              key={idx}
              eventKey={it.url}
              style={{
                backgroundColor: "#FFFFFF",
                margin: "7px 0px 7px 0px",
                borderRadius: "10px",
                color: "#000",
                outline: "none",
              }}
            >
              <NavIcon>
                <i
                  className={it.icon}
                  style={{
                    fontSize: "1.75rem",
                    color: "#000",
                    marginTop: "10px",
                    background: "#C0F6F7",
                    borderRadius: "10px",
                    padding: "2px",
                  }}
                />
              </NavIcon>
              <NavText style={{ color: "#000" }}>{it.name}</NavText>
              {this._renderChildren(it)}
            </NavItem>
          ))}
        </SideNav.Nav>
      </SideNav>
    );
  }
}

export default SideBar;
