import React from 'react'
import {
  Form,
  Container,
  Col,
  Row,
  FormGroup,
  Button,
  Card,
  Alert
} from 'react-bootstrap';

import './RegisterAdmin.css'
import ApiAccount from '../../services/api/account';
import ProtectedRoute from '../ProtectedRoute';
import { validateEmail } from '../../utils/validation'; 

const roles = ['Admin', 'Stakeholder'];

class RegisterAdmin extends React.Component {
  state = {
    email: '',
    name: '',
    password: '',
    role: 'Admin',
    description: '',
    emailInvalid: null,
    passInvalid: null,

    error: false,
    success: false,
    errorMessage: '',
  }

  _handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'email') {
        if (validateEmail(value)) {
            this.setState({
                [name]: value,
                emailInvalid: false,
            });
        } else {
            this.setState({
                emailInvalid: true,
            });
        }
    } else {
        this.setState({
            [name]: value,
        });
    }
  }

  _handleAlertClose = () => {
    this.setState({
      error: false,
      success: false,
    });
  }

  _handleSubmit = () => {
    const { email, name, password, description, role } = this.state;

    ApiAccount.addAdmin({
      email,
      name,
      password,
      description,
      role
    })
      .then(() => {
        this.setState({
          succMessage: `Successfully register the account`,
          success: true,
        })
      })
      .catch((e) => {
        this.setState({
          error: true,
          errorMessage: e.response.data.error,
        })
      });
  }

  render() {
    const { emailInvalid, password, name, error, errorMessage, succMessage, success  } = this.state;

    return (
      <ProtectedRoute>
        <Alert
          show={error || success}
          className="alert-custom"
          variant={error ? 'danger' : 'success'}>
          {error ? errorMessage : succMessage}
          <button type="button" class="close" data-dismiss="alert" aria-label="Close" onClick={this._handleAlertClose}>
            <span aria-hidden="true">&times;</span>
          </button>
        </Alert>
        <Container className="py-3 container-register-admin">
          <Row className="">
            <Col sm={12}>
              <Card className="card-register-admin">
                <Card.Header style={{backgroundColor:"#C0F6F7"}}>
                  <h1 className="text-left">
                    <strong>
                      Register Admin
                    </strong>
                  </h1>
                </Card.Header>
                <Card.Body>
                  <Form>
                    <FormGroup as={Row}>
                      <Form.Label column sm="4" className="text-left">Nama</Form.Label>
                      <Col sm="8">
                        <Form.Control
                          onChange={this._handleChange}
                          name="name"
                          placeholder="Masukan nama admin disini" />
                      </Col>
                    </FormGroup>
                    <FormGroup as={Row}>
                      <Form.Label column sm="4" className="text-left">Deskripsi</Form.Label>
                      <Col sm="8">
                        <Form.Control
                          onChange={this._handleChange}
                          name="description"
                          placeholder="Masukan deskripsi disini (opsional)" />
                      </Col>
                    </FormGroup>
                    <FormGroup as={Row}>
                      <Form.Label column sm="4" className="text-left">Email Address</Form.Label>
                      <Col sm="8">
                        <Form.Control
                          onChange={this._handleChange}
                          name="email"
                          type="email"
                          isInvalid={emailInvalid}
                          placeholder="Masukan email admin disini" />
                        <Form.Control.Feedback type="invalid" className="text-left">
                            Format email salah
                        </Form.Control.Feedback>
                      </Col>
                    </FormGroup>
                    <FormGroup as={Row}>
                      <Form.Label column sm="4" className="text-left">Password</Form.Label>
                      <Col sm="8">
                        <Form.Control
                          onChange={this._handleChange}
                          name="password"
                          type="password"
                          placeholder="Masukan password disini" />
                      </Col>
                    </FormGroup>
                    <FormGroup as={Row}>
                      <Form.Label column sm="4" className="text-left">Role</Form.Label>
                      <Col sm="8">
                        <Form.Control as="select"
                                      name="role"
                                      onChange={this._handleChange}>
                          {
                            roles.map((role) => {
                              return (
                                <option key={role} value={role}>{role}</option>
                              )
                            })
                          }
                        </Form.Control>
                      </Col>
                    </FormGroup>
                    <Button onClick={this._handleSubmit}
                            className="pull-right py-2 mt-3 mb-2 btn-primary"
                            disabled={emailInvalid || password === '' || name === ''} >
                      Submit
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </ProtectedRoute>
    );
  }
};

export default RegisterAdmin;