import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

//List of views
import Dashboard from "./views/dashboard/Dashboard";
import ListCivitas from "./views/dashboard/ListCivitas";
import Login from "./views/auth/Login";
import Logout from "./views/auth/Logout";
import RegisterAdmin from "./views/admin/RegisterAdmin";
import UploadForm from "./views/files/UploadForm";

import SideBar from "./components/sidebar/SideBar";
import "./App.css";

function App() {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route
            render={({ location, history }) => (
              <React.Fragment>
                <SideBar
                  onToggle={(expanded) => {
                    setExpanded(expanded);
                  }}
                  location={location}
                  history={history}
                />
                <main
                  className="bg-covid-primary"
                  style={{
                    position: expanded ? "relative" : "",
                    left: expanded ? "145px" : "",
                  }}
                >
                  <Redirect from="/" to="/dashboard" />
                  <Route exact path="/admin/add" component={RegisterAdmin} />
                  <Route exact path="/dashboard" component={Dashboard} />
                  <Route
                    exact
                    path="/dashboard/civitas/list"
                    component={ListCivitas}
                  />
                  <Route
                    exact
                    path="/forms/upload-data"
                    component={UploadForm}
                  />

                  <Route exact path="/logout" component={Logout} />
                  {/** Add another routes here! */}
                </main>
              </React.Fragment>
            )}
          />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
