import React from "react";
import {
  Form,
  Container,
  Col,
  Card,
  Row,
  FormGroup,
  Button,
  Alert,
} from "react-bootstrap";
import "./Login.css";
import LoginImg from "../../assets/img-login.png";
import ApiAccount from "../../services/api/account";
import { validateEmail } from "../../utils/validation";

class Login extends React.Component {
  state = {
    formType: "non-civitas",
  };

  async componentDidMount() {
    const token = await localStorage.getItem("CV_ITB_TOKEN");
    if (token) {
      this.props.history.push("/dashboard");
    }
  }

  actionToggleForm = () => {
    if (this.state.formType === "civitas")
      this.setState({ formType: "non-civitas" });
    if (this.state.formType === "non-civitas")
      this.setState({ formType: "civitas" });
  };

  actionNavigateToDashboard = () => {
    this.props.history.push("/dashboard");
  };

  render() {
    return (
      <Container fluid className="container-login">
        <Row className="align-items-center mx-auto h-100">
          <Col>
            <Card className="row card-login mx-auto appear-none">
              <Col sm="6" className="p-0">
                <img src={LoginImg} alt="background-login" />
              </Col>
              <Col sm="6" className="p-4">
                {this.state.formType === "civitas" && (
                  <CivitasLogin
                    toggleForm={this.actionToggleForm}
                    navigateToDashboard={this.actionNavigateToDashboard}
                  />
                )}
                {this.state.formType === "non-civitas" && (
                  <NonCivitasLogin
                    toggleForm={this.actionToggleForm}
                    navigateToDashboard={this.actionNavigateToDashboard}
                  />
                )}
              </Col>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

class NonCivitasLogin extends React.Component {
  state = {
    email: "",
    id: "",
    password: "",
    emailInvalid: null,
    emailFocused: false,
    showAlert: false,
    isLoading: false,
  };

  _handleChange = (e) => {
    const { type, value } = e.target;

    this.setState({
      [type]: value,
    });

    if (type === "email") {
      if (validateEmail(value)) {
        this.setState({
          emailInvalid: false,
          emailFocused: true,
        });
      } else {
        this.setState({
          emailInvalid: true,
          emailFocused: true,
        });
      }
    }
  };

  _handleLostFocus = () => {
    if (validateEmail(this.state.email)) {
      this.setState({
        emailInvalid: false,
        emailFocused: false,
      });
    } else {
      this.setState({
        emailInvalid: true,
        emailFocused: false,
      });
    }
  };

  _handleEnterKey = (e) => {
    if (e.keyCode === 13) {
      //Enter key
      document.getElementById("submit").click();
    }
  };

  _handleLogin = () => {
    this.setState({
      isLoading: true,
    });

    ApiAccount.login({
      email: this.state.email,
      password: this.state.password,
    })
      .then((res) => {
        this.setState({
          isLoading: false,
        });

        localStorage.setItem("CV_ITB_ROLE", res.data.account.role);
        this.props.navigateToDashboard();
      })
      .catch((_) => {
        this.setState({
          isLoading: false,
          showAlert: true,
        });
      });
  };

  _onAlertClose = () => {
    this.setState({
      showAlert: false,
    });
  };

  render() {
    const {
      emailInvalid,
      email,
      emailFocused,
      password,
      showAlert,
      isLoading,
    } = this.state;

    return (
      <Form className="p-4 mb-4">
        <h1 className="mb-4 mt-2">Sign in</h1>
        <Alert
          variant={"danger"}
          show={showAlert}
          dismissible
          onClose={this._onAlertClose}
        >
          Invalid credential
        </Alert>
        <FormGroup as={Row} className="mb-0">
          <Form.Label column sm="12" className="text-left">
            Email Address
          </Form.Label>
          <Col sm="12">
            <Form.Control
              onChange={this._handleChange}
              onBlur={this._handleLostFocus}
              onKeyUp={this._handleEnterKey}
              type="email"
              isInvalid={emailInvalid && !emailFocused}
              placeholder="Masukan email anda disini"
            />
            <Form.Control.Feedback type="invalid" className="text-left">
              Format email salah
            </Form.Control.Feedback>
          </Col>
        </FormGroup>

        <FormGroup as={Row}>
          <Form.Label column sm="12" className="text-left">
            Password
          </Form.Label>
          <Col sm="12">
            <Form.Control
              onKeyUp={this._handleEnterKey}
              onChange={this._handleChange}
              type="password"
              placeholder="Masukan password anda disini"
            />
          </Col>
        </FormGroup>
        <Button
          id="submit"
          onClick={this._handleLogin}
          disabled={emailInvalid || !password || !email || isLoading}
          className="py-2 mt-4 mb-2 btn-primary"
          block
        >
          {isLoading ? "Logging In..." : "Login"}
        </Button>
        <button
          style={{
            border: "none",
            background: "none",
            margin: 10,
            fontSize: 16,
            outline: "none",
          }}
          type="button"
          onClick={() => {
            this.props.toggleForm();
          }}
        >
          Civitas? Login disini
        </button>
      </Form>
    );
  }
}

class CivitasLogin extends React.Component {
  state = {
    id: "",
    password: "",
    showAlert: false,
    isLoading: false,
  };

  _handleChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  _handleEnterKey = (e) => {
    if (e.keyCode === 13) {
      //Enter key
      document.getElementById("submit").click();
    }
  };

  _handleLogin = () => {
    this.setState({
      isLoading: true,
    });

    ApiAccount.civitasLogin({
      id: this.state.id,
      password: this.state.password,
    })
      .then((res) => {
        this.setState({
          isLoading: false,
        });

        localStorage.setItem("CV_ITB_ROLE", res.data.account.role);
        localStorage.setItem("CV_ITB_CIVITAS_ID", res.data.account.civitas_id);
        localStorage.setItem("CV_ITB_CIVITAS_NAME", res.data.account.name);
        this.props.navigateToDashboard();
      })
      .catch((_) => {
        this.setState({
          isLoading: false,
          showAlert: true,
        });
      });
  };

  _onAlertClose = () => {
    this.setState({
      showAlert: false,
    });
  };

  render() {
    const { isLoading, showAlert } = this.state;

    return (
      <Form className="p-4 mb-4">
        <h1 className="mb-4 mt-2">Sign in</h1>
        <Alert
          variant={"danger"}
          show={showAlert}
          dismissible
          onClose={this._onAlertClose}
        >
          Invalid credential
        </Alert>
        <FormGroup as={Row} className="mb-0">
          <Form.Label column sm="12" className="text-left">
            NIM/NIDN/NIDK/NIP/No. SPKWT/SK
          </Form.Label>
          <Col sm="12">
            <Form.Control
              onChange={this._handleChange}
              onKeyUp={this._handleEnterKey}
              name="id"
              isInvalid={this.state.userId}
              placeholder="Masukan id anda disini"
            />
          </Col>
        </FormGroup>
        <FormGroup as={Row}>
          <Form.Label column sm="12" className="text-left">
            Password
          </Form.Label>
          <Col sm="12">
            <Form.Control
              onKeyUp={this._handleEnterKey}
              onChange={this._handleChange}
              type="password"
              name="password"
              placeholder="Masukan password anda disini"
            />
          </Col>
        </FormGroup>
        <Button
          id="submit"
          onClick={this._handleLogin}
          disabled={!(this.state.id && this.state.password)}
          className="py-2 mt-4 mb-2 btn-primary"
          block
        >
          {isLoading ? "Logging In..." : "Login"}
        </Button>
        <button
          style={{
            border: "none",
            background: "none",
            margin: 10,
            fontSize: 16,
            outline: "none",
          }}
          type="button"
          onClick={() => {
            this.props.toggleForm();
          }}
        >
          Admin atau Stakeholder? Login disini
        </button>
      </Form>
    );
  }
}

export default Login;
